import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "Hydronic-Systems-Logo3_2014.png" }) {
        childImageSharp {
          fixed(height:47) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return <Img className={`logo`} fixed={data.logo.childImageSharp.fixed} />
}

export default Logo
