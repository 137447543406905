import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const carouselData = [
  // {
  //   heading:'Instinct Solo & Combi High Efficiency Boilers',
  //   bulletTitle:'Introducing the Instinct Solo<br/>& Combi High Efficiency Boilers:',
  //   bullets:'Best warranty in the industry,Delivers 5 GPM of domestic hot water,Stainless steel fire tube design',
  //   image:'banner3'
  // },
  {
    heading: "NEW ALTA Combi & Heat Only Boilers",
    bulletTitle: "NEW ALTA Combi & Heat Only Boilers",
    bullets:
      "Gas Adaptive Technology, Low Gas Pressure Capability, Indoor Reset (No Outdoor Sensor)",
    image: "banner4",
  },
  {
    heading: "NEW VESTA Combi & Heat Only Boilers",
    bulletTitle: "NEW VESTA Combi & Heat Only Boilers",
    bullets:
      "Stainless Steel Heat Exchanger, Up To 5 GPM Domestic Hot Water, Intuitive Control & Simple Setup",
    image: "banner5",
  },
  {
    heading: "Radiators, Convectors and Towel Warmers",
    bulletTitle: "Featuring High Quality:",
    bullets: "European-style Radiators,Fan Convectors,Towel Warmers",
    image: "banner0",
  },
  // {
  //   heading: "Boilers and Indirect Fired Water Heaters",
  //   bulletTitle: "Featuring High Efficiency:",
  //   bullets: "Condensing Boilers,Indirect Fired Water Heaters",
  //   image: "banner1",
  // },
  {
    heading: "Hydronic Controls and Themostats",
    bulletTitle: "Featuring Energy Saving:",
    bullets: "WIFI Thermostats,Hydronic Controls,Snow Melting Systems",
    image: "banner2",
  },
]

class Carousel extends React.PureComponent {
  constructor(props, context) {
    super(props, context)
    this.data = props.data
    this.current = 0
    this.length = carouselData.length
    this.BANNER_ID = "homepage-banner-"
    this.enter = "enter"
    this.hide = "hide"
    this.exit = "exit"
    this.active = "active"

    this.dly1 = setTimeout(() => {}, 0)
    this.dly2 = setTimeout(() => {}, 0)
    this.dly3 = setTimeout(() => {}, 0)

    this.gotoCarousel = this.gotoCarousel.bind(this)
    this.stopCarousel = this.stopCarousel.bind(this)
    this.startCarousel = this.startCarousel.bind(this)
  }

  componentWillUnmount() {
    this.stopCarousel()
    this.current = 0
  }

  gotoCarousel(idx) {
    // console.log('GO TO CAROUSEL '+this.current)
    this.stopCarousel()

    let $current = document.getElementById(this.BANNER_ID + this.current)
    if (typeof $current !== "undefined" && $current !== null) {
      $current.classList.add(this.exit)
      this.dly2 = setTimeout(() => {
        $current.classList.remove(this.active)
        $current.classList.remove(this.exit)
        $current.classList.add(this.hide)
        $current.classList.add(this.enter)

        this.current = idx

        const $next = document.getElementById(this.BANNER_ID + this.current)

        // console.log('NEXT')
        // console.log($next)

        $next.classList.add(this.active)
        $next.classList.remove(this.hide)
        this.dly3 = setTimeout(() => {
          $next.classList.remove(this.enter)
          $current = $next

          this.startCarousel()
        }, 200)
      }, 10)
    } else this.startCarousel()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  stopCarousel() {
    clearInterval(this.dly1)
    clearInterval(this.dly2)
    clearInterval(this.dly3)
  }

  startCarousel() {
    // console.log('START CAROUSEL')
    let $current = document.getElementById(this.BANNER_ID + this.current)
    let useNext = false

    if (typeof $current !== "undefined" && $current !== null) {
      // console.log('NOT UNDEF')
      // console.log($current)
      $current.classList.remove("enter")

      this.stopCarousel()

      this.dly1 = setInterval(() => {
        this.current = (this.current + 1) % this.length

        // console.log('NEW CURR')
        // console.log(this.current)

        $current.classList.add(this.exit)

        this.dly2 = setTimeout(() => {
          $current.classList.remove(this.active)
          $current.classList.remove(this.exit)
          $current.classList.add(this.hide)
          $current.classList.add(this.enter)

          const $next = document.getElementById(this.BANNER_ID + this.current)
          // console.log('NEXT')
          // console.log($next)
          $next.classList.add(this.active)
          $next.classList.remove(this.hide)
          this.dly3 = setTimeout(() => {
            $next.classList.remove(this.enter)
            $current = $next
          }, 200)
        }, 400)
      }, 1000 * 5 * (this.current === 0 ? 2 : 1))
    } //else console.log('UNDEF')
  }

  componentDidMount() {
    // console.log("COMPONENT DID MOUNT")
    this.startCarousel()
  }

  render() {
    // console.log('CAOURSEL DATA')
    // console.log(this.data)
    const data = this.data
    return (
      <div id="coursel-wrap">
        {carouselData.map((el, i) => {
          return (
            <div
              key={`homepage-banner-${i}`}
              id={`homepage-banner-${i}`}
              className={`banner ${i === 0 ? `active` : `hide`} enter`}
              data-bannerno={i}
            >
              <div className="banner-text">
                <h2 dangerouslySetInnerHTML={{ __html: el.bulletTitle }} />
                <ul>
                  {el.bullets.split(",").map((bullet, j) => {
                    return (
                      <li key={`banner-${i}-bullet-${j}`} className={`enter`}>
                        {bullet.trim()}
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className={`banner-image`}>
                <Img
                  fluid={data[el.image].childImageSharp.fluid}
                  alt={`Image of ${el.heading}`}
                />
              </div>
            </div>
          )
        })}
        <ul className={`carousel-controller`}>
          {carouselData.map((_, i) => (
            <li
              onClick={() => {
                this.gotoCarousel(i)
              }}
              id={`homepage-banner-idx-${i}`}
              key={`homepage-banner-idx-${i}`}
              className={`carousel-control ${i === 0 ? `active` : `active`}`}
            />
          ))}
        </ul>
      </div>
    )
  }
}

const CarouselWrapper = () => {
  const data = useStaticQuery(graphql`
    query {
      banner0: file(relativePath: { eq: "banner0.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      banner1: file(relativePath: { eq: "banner1.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      banner2: file(relativePath: { eq: "banner2.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      banner3: file(relativePath: { eq: "banner3.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      banner4: file(relativePath: { eq: "banner4.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      banner5: file(relativePath: { eq: "banner5.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="banners">
      <Carousel data={data} />
    </div>
  )
}

export default CarouselWrapper
