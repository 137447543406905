import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

import Img from "gatsby-image"

import Menu from "./menu"
import { FaLinkedin } from "react-icons/all"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      flame: file(relativePath: { eq: "hsci_flame.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <footer id="footer" className="clearfix">
      <Menu />
      <div className="footer-info">
        <p>
          <strong>Hydronic Systems Canada Inc.</strong>
        </p>
        <div className="contact-info clearfix">
          <div className="col">
            34 Meteor Drive
            <br />
            Toronto, ON M9W 1A4
          </div>
          <div className="col">
            <a href="tel:(416) 675-7651">(416) 675-7651</a>{" "}
            <a href="mailto:customerservice@hydronicsystems.ca">
              customerservice@hydronicsystems.ca
            </a>
          </div>
        </div>
        <div className="contact-info" style={{ marginTop: "15px" }}>
          <div className="">
            <a
              className="social-link"
              href={`https://www.linkedin.com/company/hydronicsystemscanadainc/`}
            >
              <FaLinkedin /> Follow Us On LinkedIn
            </a>
          </div>
        </div>
      </div>
      <Img
        fluid={data.flame.childImageSharp.fluid}
        className="flame"
        alt="Image of Flame"
      />
    </footer>
  )
}

export default Footer
