import React from "react"
import { Link } from "gatsby"
import { FaLinkedin } from "react-icons/all"

const Menu = () => {
  return (
    <>
      <ul className="inline-list menu">
        <li>
          <Link to={`/`}>Home</Link>
        </li>
        <li>
          <Link to={`/aboutus/`}>About Us</Link>
        </li>
        <li>
          <Link to={`/products/`}>Products</Link>
        </li>
        <li>
          <Link to={`/manufacturers/`}>Manufacturers</Link>
        </li>
        <li>
          <Link to={`/training/`}>Events</Link>
        </li>
        <li>
          <Link to={`/promotions/`}>Promotions</Link>
        </li>
        <li>
          <Link to={`/whatsNew/`}>What's New?</Link>
        </li>
      </ul>
      <ul className="inline-list sub menu">
        <li>
          <Link to={`/contact/`}>Contact Us</Link>
        </li>
        <li className="footer-only">
          <Link to={`/priceList/`}>Price Lists</Link>
        </li>
      </ul>
    </>
  )
}

export default Menu
