import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Menu from "./menu"
import Logo from "./logo"
import Carousel from "./carousel"

const Header = ({ headerStyle }) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <header id={`header`} className={headerStyle}>
      <div className="bg-img" />
      <div className="bg-over" />
      <Link aria-label={"Click to go home"} to={"/"}>
        <Logo />
      </Link>
      <nav role="navigation" className={!expanded ? "rest" : ""}>
        <Menu />
        <div
          onClick={() => setExpanded(prev => !prev)}
          className="menu-btn clearfix"
        >
          <div className="bars">
            <span className="bar" />
            <span className="bar" />
            <span className="bar" />
          </div>
          <div className="cta">
            <span> Menu</span>
          </div>
        </div>
      </nav>

      {headerStyle === "index" ? <Carousel /> : ``}
    </header>
  )
}

Header.defaultProps = {
  headerStyle: "",
}

Header.propTypes = {
  headerStyle: PropTypes.string,
}

export default Header
