/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./styles.scss"

const Layout = ({ children, headerStyle }) => {

  return (
    <>
      <Header headerStyle={headerStyle} />
      <main id={`wrapper`}>
        <div id={`inner-wrap`}>
          {children}
        </div>

      </main>
      <Footer />
    </>
  )
}



export default Layout
